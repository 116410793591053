import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BidThunks } from '../../thunks/bid.thunks';
import { IBidRepository } from 'shared/api/interfaces/IBidRepository';
import { container } from 'shared/api/inversify.config';
import { SERVICE_KEYS } from 'shared/api/keys.const';
import { IBid, IBidAPI } from 'shared/models/Bid';
import { getBidKey } from './bid.utils';
import { normalizeBid } from './bid.normalize';

type BidKey = string;
export type BidMap<T = IBid> = { [key: BidKey]: T };

export type BidSlice = {
  bids: BidMap;
  initialLoadComplete: boolean;
};

const initialState: BidSlice = {
  bids: {},
  initialLoadComplete: false
};

const bidRepository = container.get<IBidRepository>(SERVICE_KEYS.BID_REPOSITORY);
const bidThunks = new BidThunks(bidRepository);

export const submitBid = bidThunks.add();
export const fetchBids = bidThunks.getAll();
export const fetchByMortgageId = bidThunks.fetchByMortgageId();
export const acceptBidAsOriginator = bidThunks.acceptBidAsOriginator();
export const cancelAcceptedBidAsOriginator = bidThunks.cancelAcceptedBidAsOriginator();
export const confirmBidAsInvestor = bidThunks.confirmBidAsInvestor();
export const proposeRevisionAsInvestor = bidThunks.proposeRevisionAsInvestor();
export const acceptRevisionAsOriginator = bidThunks.acceptRevisionAsOriginator();
export const acceptRevisionAsOriginatorFreddie = bidThunks.acceptRevisionAsOriginatorFreddie();
export const rejectRevisionAsOriginator = bidThunks.rejectRevisionAsOriginator();
export const cancelBid = bidThunks.cancelBid();

const _setBids = (state: BidSlice, action: PayloadAction<{ data: IBidAPI[] }>) => {
  const { payload } = action;

  const bids: BidMap = {};

  for (const bid of payload.data) {
    const normalizedBid = normalizeBid(bid);
    const bidKey = getBidKey(bid);

    bids[bidKey] = normalizedBid;
  }
  state.bids = bids;

  state.initialLoadComplete = true;
};

const _updateBid = (state: BidSlice, { payload }: PayloadAction<{ data: IBidAPI }>) => {
  const incomingBid = normalizeBid(payload.data);

  state.bids = {
    ...state.bids,
    [getBidKey(incomingBid)]: incomingBid
  };
};

const bidSlice = createSlice({
  name: 'bid',
  initialState,
  reducers: {
    setBids: _setBids,
    updateBid: _updateBid
  },
  extraReducers: (builder) => {
    builder.addCase(acceptBidAsOriginator.fulfilled, _updateBid);

    builder.addCase(cancelBid.fulfilled, (state, { meta }) => {
      const bid = meta.arg;

      delete state.bids[getBidKey(bid)];
      state.bids = {
        ...state.bids
      };
    });

    builder.addCase(submitBid.fulfilled, _updateBid);

    builder.addCase(fetchBids.fulfilled, _setBids);
  }
});

export default bidSlice.reducer;

export const { setBids, updateBid } = bidSlice.actions;
