import { useNavigate, useParams } from 'react-router-dom';
import FilterButtons from '../FilterButtons';
import './index.scss';
import { RouteUtils } from 'Sections/Originator/Pages/Quality/RouteUtils';

interface ICategoryHeaderContentProps {
  isLoading?: boolean;
}

export enum ChecklistReviewFilter {
  Review = 'Review',
  Complete = 'Complete',
  All = 'All'
}

const buttons = [ChecklistReviewFilter.Review, ChecklistReviewFilter.Complete, ChecklistReviewFilter.All];

const ChecklistTablePanelHeader = ({ isLoading = false }: ICategoryHeaderContentProps) => {
  const navigate = useNavigate();
  const { mortgageId = '', filter = '' } = useParams();

  const onFilterChange = (nextStatus: ChecklistReviewFilter) => {
    navigate(RouteUtils.closeChecklistWithParams(mortgageId, nextStatus));
  };

  return (
    <div className="column-header">
      <div className="content-section">
        <FilterButtons<ChecklistReviewFilter>
          onChange={onFilterChange}
          buttons={buttons}
          isLoading={isLoading}
          selected={filter}
        />
      </div>
    </div>
  );
};

export default ChecklistTablePanelHeader;
