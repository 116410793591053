import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import {
  selectDeactivatedMortgages,
  selectOpenMortgages,
  selectQualityMortgages,
  selectSoldMortgages
} from '../mortgage/mortgage.selectors';
import { selectDocumentStatus, selectLQAValidations } from '../analysis-documents/selectors';
import { IAllLoansTableData } from '../../../Sections/Originator/Pages/Quality/types';
import { getBidMap } from '../bid/bid.utils';
import { selectBids } from '../bid/bid.selectors';
import { DocumentStatus, IDocumentStatus } from '../../../shared/models/Analysis-Document';
import { IMortgage, IMortgageFlat } from 'shared/models/Mortgage';
import { NormalizedLQAValidation, NormalizedLQAValidationStatus } from './types';
import { RootState } from 'state-management/store';
import { selectConfig } from '../config/config.selectors';
import { Config } from '../config/config.slice';

const mapMortgagesForTable = (
  mortgages: IMortgageFlat[],
  documentStatus: Record<string, IDocumentStatus>,
  lqaStatus: Record<IMortgage['internalId'], NormalizedLQAValidation>,
  config: Config
) => {
  const isAutopassModeEnabled = Boolean(config.featureFlags?.showAutoPassMode);

  return mortgages.map((mortgage) => {
    const isLoading = !documentStatus;
    const isPending = !isLoading && !documentStatus[mortgage.internalId]; // API does not return results if there are no documents
    const rulesTodo = isAutopassModeEnabled
      ? documentStatus[mortgage.internalId]?.rulesFailing
      : documentStatus[mortgage.internalId]?.rulesFailing + documentStatus[mortgage.internalId]?.rulesAutoPass;

    return {
      ...mortgage,
      closeChecklist: {
        isLoading,
        isPending: isPending || documentStatus[mortgage.internalId]?.status === DocumentStatus.UNSTARTED,
        errors: rulesTodo
      },
      documentCategorization: {
        isPending,
        isLoading,
        errors: documentStatus[mortgage.internalId]?.docsUncategorized ?? 0
      },
      loanQualityAdvisor: {
        isPending: lqaStatus[mortgage.internalId]?.status !== NormalizedLQAValidationStatus.COMPLETE,
        isLoading: !lqaStatus[mortgage.internalId],
        errors: lqaStatus[mortgage.internalId]?.errorsCount ?? 0
      }
    };
  });
};

export const selectAllDraftTableMortgages = createSelector(
  selectQualityMortgages,
  selectDocumentStatus,
  selectLQAValidations,
  selectConfig,
  ({ draft }, documentStatus, lqaValidations, config): IAllLoansTableData[] => {
    // Sort by planned closing date
    const sortedMortgages = sortBy(draft, ['plannedClosingDate']);

    return mapMortgagesForTable(sortedMortgages, documentStatus, lqaValidations, config);
  }
);

export const selectAllPendingTableMortgages = createSelector(
  selectQualityMortgages,
  selectBids,
  selectDocumentStatus,
  selectLQAValidations,
  selectConfig,
  ({ pending }, bids, documentStatus, lqaValidations, config): IAllLoansTableData[] => {
    // Add delivery date
    let deliveryDate;
    const bidsMap = getBidMap(pending, bids);
    const mortgagesWithDeliveryDate = pending.map((mortgage) => {
      deliveryDate = bidsMap[mortgage.internalId]?.[0]?.deliveryDate ?? null;

      // bids may not be loaded yet
      if (bidsMap[mortgage.internalId]?.[0] && !deliveryDate) {
        console.error('No delivery date for mortgage', mortgage.internalId, mortgage.loanNumber);
      }

      return { ...mortgage, deliveryDate };
    });

    // Sort by delivery date first,
    const sortedMortgages = sortBy(mortgagesWithDeliveryDate, ['deliveryDate']);

    return mapMortgagesForTable(sortedMortgages, documentStatus, lqaValidations, config);
  }
);

export const selectAllOpenTableMortgages = createSelector(
  selectOpenMortgages,
  selectDocumentStatus,
  selectLQAValidations,
  selectConfig,
  (open, documentStatus, lqaValidations, config): IAllLoansTableData[] => {
    return mapMortgagesForTable(open, documentStatus, lqaValidations, config);
  }
);

export const selectAllDeactivatedTableMortgages = createSelector(
  selectDeactivatedMortgages,
  selectDocumentStatus,
  selectLQAValidations,
  selectConfig,
  (deactivated, documentStatus, lqaValidations, config): IAllLoansTableData[] => {
    return mapMortgagesForTable(deactivated, documentStatus, lqaValidations, config);
  }
);

export const selectAllSoldTableMortgages = createSelector(
  selectSoldMortgages,
  selectDocumentStatus,
  selectLQAValidations,
  selectConfig,
  (sold, documentStatus, lqaValidations, config): IAllLoansTableData[] => {
    return mapMortgagesForTable(sold, documentStatus, lqaValidations, config);
  }
);

export const selectAllStatusMortgages = createSelector(
  selectAllDraftTableMortgages,
  selectAllPendingTableMortgages,
  selectAllSoldTableMortgages,
  selectAllDeactivatedTableMortgages,
  selectAllOpenTableMortgages,
  (draft, pending, sold, deactivated, open): IAllLoansTableData[] => {
    return [...draft, ...pending, ...sold, ...deactivated, ...open];
  }
);

const _selectStatusMortgage = createSelector(
  selectAllStatusMortgages,
  (_: RootState, mortgageId: string) => mortgageId,
  (allLoans, mortgageId) => {
    if (!mortgageId) {
      return null;
    }

    return allLoans.find((loan) => loan.internalId === mortgageId);
  }
);

// TODO, use this selector to replace other usages for https://superflylabs.atlassian.net/browse/HD-5827
export const makeSelectStatusMortgage = () => _selectStatusMortgage;

/**
 * @deprecated Use selectAllStatusMortgages instead
 */
export const selectAllTableMortgages = selectAllStatusMortgages;
